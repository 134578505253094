<template>
  <div class="tab">
    <div class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent" style="overflow:auto; max-height: calc(100vh - 190px)">
      <div
        v-if="iOS"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;width: 100%;border: solid 1px #c0c0c0;background: #f1f1f1;padding: 4px;border-radius: 3px;margin-bottom: 25px !important;"
      >
        <div style="font-size:0.8rem;font-weight:bold;">
          If you have low memory or storage, please consider using an alternative device to avoid possible issues recording your answers.
        </div>
      </div>

      <div
        v-if="job.AudioOnly === 0"
        class="text-center mb-4 font-weight-bold"
        style="font-size:0.9rem;"
      >
        About your video interview
      </div>
      <div
        v-else
        class="text-center mb-4 font-weight-bold"
        style="font-size:0.9rem;"
      >
        About your interview
      </div>

      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          <span>Before your interview starts you will be</span>
          <span v-if="job.RequestIDCheck"> asked to provide your identification and then you will be presented with a practice question.</span>
          <span v-else> presented with a practice question.</span>
          <span> You can retry the practice question as many times as you wish. However please note that <span style="font-weight:bold;">as soon as you complete the practice question you will enter your interview</span>.</span>
        </div>
      </div>

      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div v-if="answerableQuestionCount === 1" style="font-size:0.9rem;">
          Your interview is comprised of <span style="font-weight:bold;">1 question</span>. You are required to record a response to this question.
        </div>
        <div v-else style="font-size:0.9rem;">
          Your interview is comprised of <span style="font-weight:bold;">{{ answerableQuestionCount }} questions</span>. You are required to record a response to each question.
        </div>
      </div>
      
      <div
        v-if="job.AllowRetries"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          <span v-if="job.DisablePlayback">You will not be able to watch back your answers once recorded and the screen will show 'PLAYBACK DISABLED'.</span>
          <span v-else>You will be able to watch your answers once recorded.</span>
        </div>
      </div>

      <div
        v-if="job.AllowRetries === false"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div v-if="answerableQuestionCount === 1" style="font-size:0.9rem;">
          You are permitted <span style="font-weight:bold;">1 attempt</span> to answer this question as indicated by the 
          <i
            class="fa-sync fa mx-2"
            title="Sync icon"
            alt="Sync icon"
          /><span class="sr-only"> sync</span> icon.
        </div>
        <div v-else style="font-size:0.9rem;">
          You are permitted <span style="font-weight:bold;">1 attempt</span> to answer each question as indicated by the 
          <i
            class="fa-sync fa mx-2"
            title="Sync icon"
            alt="Sync icon"
          /><span class="sr-only"> sync</span> icon.
        </div>
      </div>
      <div
        v-if="job.AllowRetries && job.RetryCount === 1"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div v-if="answerableQuestionCount === 1" style="font-size:0.9rem;">
          You are permitted <span style="font-weight:bold;">2 attempts</span> to answer this question as indicated by the 
          <i
            class="fa-sync fa mx-2"
            title="Sync icon"
            alt="Sync icon"
          /><span class="sr-only"> sync</span> icon.  You should choose <span style="font-weight:bold;">'RE-RECORD'</span> if you wish to try again, or <span style="font-weight:bold;">'NEXT'</span> to continue.
        </div>
        <div v-else style="font-size:0.9rem;">
          You are permitted <span style="font-weight:bold;">2 attempts</span> to answer each question as indicated by the 
          <i
            class="fa-sync fa mx-2"
            title="Sync icon"
            alt="Sync icon"
          /><span class="sr-only"> sync</span> icon.  You should choose <span style="font-weight:bold;">'RE-RECORD'</span> if you wish to try again, or <span style="font-weight:bold;">'NEXT'</span> to continue.
        </div>
      </div>
      <div
        v-if="job.AllowRetries && job.RetryCount === 999"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          You are permitted to answer as many times as you like.
        </div>
      </div>
      <div
        v-if="job.AllowRetries && job.RetryCount > 1 && job.RetryCount !== 999"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div v-if="answerableQuestionCount === 1" style="font-size:0.9rem;">
          You are permitted <span style="font-weight:bold;">{{ job.RetryCount+1 }} attempts</span> to this question as indicated by the 
          <i
            class="fa-sync fa mx-2"
            title="Sync icon"
            alt="Sync icon"
          /><span class="sr-only"> sync</span> icon. You should choose <span style="font-weight:bold;">'RE-RECORD'</span> if you wish to try again, or <span style="font-weight:bold;">'NEXT'</span> to continue.
        </div>
        <div v-else style="font-size:0.9rem;">
          You are permitted <span style="font-weight:bold;">{{ job.RetryCount+1 }} attempts</span> to answer each question as indicated by the
          <i
            class="fa-sync fa mx-2"
            title="Sync icon"
            alt="Sync icon"
          /><span class="sr-only"> sync</span> icon. You should choose <span style="font-weight:bold;">'RE-RECORD'</span> if you wish to try again, or <span style="font-weight:bold;">'NEXT'</span> to continue.
        </div>
      </div>

      <div
        v-if="job.AllowRetries && !job.PressuredRetry"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          <span>Once you have recorded an answer, you can decide whether to submit that answer, or wipe it and re-record. Once you have submitted an answer to a question you will be not be able to re-record that answer.</span>
        </div>
      </div>

      <div
        v-if="job.PressuredRetry"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          <span>After you have recorded an answer, you will have <span style="font-weight:bold;">{{ pressureTime }}</span> to decide if you wish to keep this answer or wipe it and re-record. If you do not respond within {{ pressureTime }}, your answer will be accepted.</span>
        </div>
      </div>

      <div
        v-if="job.AllowRetries === false"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          Your answer will be submitted once your recording is finished. Once you have submitted an answer to a question you will be not be able to re-record that answer.
        </div>
      </div>
      
      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div v-if="job.ReadingTime > 0" style="font-size:0.9rem;">
          Once a question has been presented to you on screen, you will have <span style="font-weight:bold;">{{ prepTime }}</span> to think about your response before recording will begin.
        </div>
        <div v-else style="font-size:0.9rem;">
          You have unlimited time to think about your response to the question and may start recording when you are ready to begin.
        </div>
      </div>

      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          The time next to the timer icon 
          <i
            class="fa-clock fa mx-2"
            title="Timer icon"
            alt="Timer icon"
          /> under each question denotes how much time you have to record your answer once recording has started.
        </div>
      </div>

      <div
        v-if="job.AudioOnly === 0"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          If enabled, the camera icon 
          <i
            class="fa-camera fa mx-2"
            title="Camera icon"
            alt="Camera icon"
          /> allows you to toggle between your front and rear facing camera.
        </div>
      </div>

      <div
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          This icon <i
            class="fa-eye fa mx-2"
            title="Eye icon"
            alt="Eye icon"
          /><span class="sr-only"> (eye icon)</span> will toggle hiding your face whilst recording. Note your video feed will still record what it sees.
        </div>
      </div>

      <div
        v-if="job.LockInterviewAfterFirstAttempt === true"
        class="d-flex align-items-start justify-content-start mb-2 pd-1"
        style="width:100%;"
      >
        <i
          class="fa fa-check mr-3 p-1"
          style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);"
        />
        <div style="font-size:0.9rem;">
          You have one attempt to complete this interview. After the practice question, closing down the browser or refreshing the page will lock the interview.
        </div>
      </div>

      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;font-weight: bold;">
          Please ensure that you turn off any screenlocks, auto-locks or screensavers. Close any open browser tabs, or apps, even those running in the background. We also recommend you disable
          notifications whilst you complete the interview. VPN's are not recommended. Also check you have a reliable internet and that your ISP is not throttling your bandwidth that could interrupt the upload process.
        </div>
      </div>
    
      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;font-weight: bold;">
          Please avoid minimising your browser or switching tabs during the interview process. This can affect the recording and uploading of your responses. 
        </div>
      </div>
    
      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          It is recommended you have the <span style="font-weight:bold;">latest operating system installed</span> on your device. We <span style="font-weight:bold;">do not support obsolete versions or beta releases</span> especially on mobile devices.
        </div>
      </div>

      <div class="d-flex align-items-start justify-content-start mb-2 pd-1" style="width:100%;">
        <i class="fa fa-check mr-3 p-1" style="height:fit-content; background:var(--pr-color);border-radius:50%;color:var(--lk-color);" />
        <div style="font-size:0.9rem;">
          Finally, if you have any technical issues, please email <a href="mailto:support@shineinterview.com" style="font-weight:bold;color:var(--pr-color);"><span style="font-weight:bold;color:var(--pr-color);">support@shineinterview.com</span></a> after checking out our troubleshooting guide
          <a 
            class="primary-colour"
            href="https://support.shineinterview.com/hc/en-us/articles/29858095985297-General-Guidelines-and-Troubleshooting"
            target="_blank"
          >
            <strong>HERE</strong>
          </a> first. The support team at Shine will
          endeavour to help you get up and running with your video interview. Please note they cannot assist with unlocking your interview, 
          resets, or provide feedback regarding your application. Please contact your recruiter for such queries.<br><br>
        </div>
      </div>
    </div>

    <div class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="handleNextPage()"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ShineGoPreamble',
	components: {
	},
	props: {
	},
	data () {
		return {
			prepTime: '',
			iOS: false,
			isMac: false,
			mobileView: false
		}
	},
	computed: {
		answerableQuestionCount () {
			return this.candidateQuestions.filter(q => q.ScenarioTitle === 'N/A').length - 1
		},
		...mapGetters(['candidateQuestions', 'job']),
		pressureTime () {
			if (this.job.PressuredRetryTime !== -1) {
				return `${this.job.PressuredRetryTime} seconds`
			} else if (this.job.PressuredRetryTime === 99) {
				return 'the length of your response'
			} else { 
				return 'unlimited time'
			}
		}
	},
	created () {
		const mins = Math.floor(this.job.ReadingTime / 60)
		let secs = this.job.ReadingTime % 60
		secs = secs < 10 ? '0' + secs : secs
		if (mins === 1 && secs === 1) {
			this.prepTime = `${mins} minute and ${secs} second`
		}
		if (mins === 1 && secs === 0) {
			this.prepTime = `${mins} minute`
		}
		if (mins > 1 && secs === 1) {
			this.prepTime = `${mins} minutes and ${secs} second`
		} 
		if (mins > 1 && secs > 1) {
			this.prepTime = `${mins} minute and ${secs} seconds`
		}
		if (mins === 0 && secs === 1) {
			this.prepTime = `${secs} second`
		}
		if (mins === 0 && secs > 0) {
			this.prepTime = `${secs} seconds`
		}
		if (mins === 0 && secs === 0) {
			this.prepTime = `unlimited time`
		}
		if (/mobile/i.test(navigator.userAgent)) {
			if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
				this.mobileView = false
				this.iOS = true
			} else {
				this.mobileView = true
				this.iOS = false
			}
		} else {
			this.mobileView = false  
			this.iOS = false
		}
		this.iOS = ['iPhone', 'iPad'].indexOf(navigator.platform) >= 0
		this.isMac = navigator.platform.indexOf('Mac') > -1

		if (navigator.userAgent.includes('Mac')) {
			this.isMac = true;
		}

		if (navigator.userAgent.includes('iPhone')) {
			this.iOS = true;
		}

		if (navigator.userAgent.includes('iPad')) {
			this.iOS = true;
		}
    
		if (this.isMac) { this.iOS = true }
	},
	methods: {
		handleNextPage () {
			if (this.job.RequestIDCheck) {
				this.$store.commit('SET_TABDESC', 'RequestIDCheck-FirstMessage')
			} else {
				this.$store.commit('SET_TABDESC', 'Recording')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@media (min-width: 577px) {
  .pd-1 {
    padding: 0.5rem !important;
  }
}
</style>
