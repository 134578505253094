<template>
  <mdb-modal
    id="helpModal"
    :show="show"
    size="md"
    @close="$emit('closeModal', false)"
  >
    <mdb-modal-header class="px-2 py-1 d-flex align-items-center">
      <mdb-modal-title class="p-1">
        <div
          class="nav-colour p-2"
          style="border-radius:5px;"
        >
          <img 
            :src="branding.Logo"
            style="height:10vw;min-height:40px;max-height:40px;"
            title="Logo"
            alt="Logo"
          >
        </div>
      </mdb-modal-title>
    </mdb-modal-header>

    <mdb-modal-body style="min-height:225px;" class="d-flex justify-content-center">
      <div style="font-size:0.9rem;margin-top:20px;margin-left:0px;margin-right:10px;">
        If you require help be it general preparations before you start your video interview, or checking your camera and microphone etc, we recommend you read through our help desk articles by
        <b-link
          style="color:#555 !important;font-weight:bold;"
          href="https://support.shineinterview.com/hc/en-us/articles/29858095985297-General-Guidelines-and-Troubleshooting"
          target="_blank"
        >
          clicking here. 
        </b-link>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	props: {
		show: {
			type: Boolean,
			required: true
		}
	},
  
	computed: {
		...mapGetters(["branding"])
	}
}
</script>

<style scoped>
  @media (max-width: 640px) {
    #helpModal .modal-dialog  {
      margin: 0px;
      height:100vh !important;
      width:100vw !important;
    }
    #helpModal .modal-content {
      height:100% !important;
      width:100vw !important;
    }
  }

  a {
    color: #007bff !important;
  }
</style>
