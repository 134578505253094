<template>
  <div class="tab">
    <div
      class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent"
      style="background:white;"
      :style="{'height': mobileView ? '100%' : ''}"
    >
      <div
        v-if="job.AudioOnly === 0"
        class="text-center mb-4 mt-2 font-weight-bold"
        style="font-size:0.9rem;"
      >
        Is your video and audio working correctly?
      </div>
      <div
        v-else
        class="text-center mb-4 mt-2 font-weight-bold"
        style="font-size:0.9rem;"
      >
        Is your audio working correctly?

        <!-- MICROPHONE TEST -->
        <div
          v-if="!showAlternativeMicMessage && iOS"
          style="height:100px;max-width:85%;"
          :class="{ 'audioMargin': job.AudioOnly === 1}"
        >
          <MicrophoneTest
            :i-o-s="iOS"
            @showAlternativeMicMessage="showAlternativeMicMessage = false"
          />
        </div>
      </div>

      <div>
        <table>
          <tr
            v-if="job.AudioOnly === 0"
          >
            <td style="padding:20px;">
              Video
              <span style="width:275px;cursor:pointer;">
                <mdb-popover
                  trigger="click"
                  :options="{placement: 'left'}"
                  style="cursor:pointer;"
                  class="grey-text"
                >
                  <div slot="body" style="padding: 5px;">
                    Video not working? Check it's not already in use elsewhere on your device.
                    <br><br>
                    Check that you've not got a privacy lock turned on.
                    <br><br>
                    If your camera is working, check where possible that the camera recording quality is set at no more than 720p (we don't need any 4K videos). For example on an iPad go to Settings --> Camera --> Record Video (max 720p).
                    <br><br>
                    Finally, sometimes a browser refresh sorts everything out!
                  </div>
                  <span slot="reference"><i class="fas fa-info-circle" /></span>
                </mdb-popover>
              </span>
            </td>
            <td style="padding:20px;height:300px;" class="vidHeight">
              <video
                ref="videoTest"
                class="video-fluid z-depth-1 vidWidth"
                autoplay
                type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.264"
                muted
                playsinline
                style="outline:none;margin: 0px;border-radius: 2px;height:100%;object-fit: cover;border-radius: 10px;"
                :class="{'tabletVidResize' : isTablet}"
              />
            </td>
          </tr>
          <tr
            v-if="(!showAlternativeMicMessage && job.AudioOnly === 0) || (!showAlternativeMicMessage && (job.AudioOnly === 1) && !iOS)"
          >
            <td style="padding:20px;">
              Microphone
              <span style="width:275px;cursor:pointer;">
                <mdb-popover
                  trigger="click"
                  :options="{placement: 'left'}"
                  style="cursor:pointer;"
                  class="grey-text"
                >
                  <div slot="body" style="padding: 5px;">
                    Microphone not working? Check your volume levels. 
                    <br><br>
                    Check your microphone is not on mute or is use elsewhere on your device.
                    <br><br>
                    Finally, sometimes a browser refresh sorts everything out!
                  </div>
                  <span slot="reference"><i class="fas fa-info-circle" /></span>
                </mdb-popover>
              </span>
            </td>
            <td style="height:200px;padding:20px;" class="micHeight">
              <MicrophoneTest
                :i-o-s="iOS"
                @showAlternativeMicMessage="showAlternativeMicMessage = false"
              />
            </td>
          </tr>
          <tr
            v-if="!showAlternativeMicMessage && iOS === false && isFirefox === false && mobileView"
          >
            <td style="padding:20px;">
              Battery
              <span style="width:275px;cursor:pointer;">
                <mdb-popover
                  trigger="click"
                  :options="{placement: 'left'}"
                  style="cursor:pointer;"
                  class="grey-text"
                >
                  <div slot="body" style="padding: 5px;">
                    If your batttery level is low, then please plug your device into an external power supply before continuing.
                  </div>
                  <span slot="reference"><i class="fas fa-info-circle" /></span>
                </mdb-popover>
              </span>
            </td>
            <td style="padding:20px;">
              <Battery
                v-if="!showAlternativeBatteryMessage && iOS === false"
                :i-o-s="iOS"
                :mobile-view="mobileView"
                @showAlternativeBatteryMessage="showAlternativeBatteryMessage = false"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        title="NO"
        alt="NO"
        size="sm"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="turnOffCamera();helpModalShowing = true"
      >
        NO
      </mdb-btn>
      <mdb-btn
        size="sm"
        title="YES"
        alt="YES"
        class="btnSmall"
        color="primary"
        :disabled="stream === null && job.AudioOnly === 0"
        style="width:160px;outline:none;"
        @click="goToIntroScreen1Details()"
      >
        YES
      </mdb-btn>
    </div>
    <HelpModal
      :show="helpModalShowing"
      @closeModal="helpModalShowing = $event"
    />
  </div>
</template>

<script>
import Battery from '@/components/IntroTabs/VideoAudioComponents/batteryComponent.vue'
import MicrophoneTest from '@/components/IntroTabs/VideoAudioComponents/microphoneTest.vue'
import HelpModal from '@/components/IntroTabs/helpModal'
import { mapGetters } from 'vuex'

export default {
	name: 'VideoAudioCheck',
	components: {
		Battery,
		MicrophoneTest,
		HelpModal
	},
	props: {
		iOS: Boolean,
		mobileView: Boolean
	},
	data () {
		return {
			showAlternativeBatteryMessage: false,
			showAlternativeMicMessage: false,
			stream: null,
			isFirefox: false,
			helpModalShowing: false,
			isTablet: false
		}
	},
	computed: {
		...mapGetters(['job']),
		skipIntroScreen1Details () {
			return this.job.IntroScreen1VideoUrl == "" && (this.job.IntroScreen1 === undefined || this.job.IntroScreen1 === "" || this.job.IntroScreen1 === null)
		}
	},
	created () { 
		if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
			this.isFirefox = true
		}
		this.isMac = navigator.platform.indexOf('Mac') > -1
		if (this.isMac) {
			if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
				this.isMac = true
			} else {
				this.isMac = false
			}
		}
		else {
			this.isMac = navigator.platform.indexOf('MacIntel') > -1
		}    
		if (/iPad|iPhone|iPod/.test(navigator.platform)) {
			this.isMac = true;
		}

		if (navigator.userAgent.includes('Mac')) {
			this.isMac = true;
		}

		if (navigator.userAgent.includes('iPhone')) {
			this.iOS = true;
		}

		if (navigator.userAgent.includes('iPad')) {
			this.iOS = true;
		}

		if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
			this.isTablet = true;
		}

		if (this.isMac) { this.iOS = true }

		this.turnOnCamera()
	},
	methods: {
		async goToIntroScreen1Details () {
			await this.turnOffCamera()
			if (this.skipIntroScreen1Details) {
				this.$store.commit('SET_TABDESC', 'IntroScreen2Details')
			} else {
				this.$store.commit('SET_TABDESC', 'IntroScreen1Details')
			}
		},
		async turnOffCamera () {
			var stream = this.$refs.videoTest.srcObject
			var tracks = stream.getTracks();
			tracks.forEach(function(track) {
				track.stop();
			});
			this.$refs.videoTest.srcObject = null;
			this.audioContext = null
			console.log('camera turned off after pre-check')
		},
		async turnOnCamera () {
			try {
				if (this.job.AudioOnly === 0) {
					if (this.mobileView) {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 640 } }
								],
								facingMode: { exact: "user" }
							},
							audio: true
						})
					} else {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 640 } }
								]
							},
							audio: true
						})
					}
					this.$refs.videoTest.srcObject = this.stream
				} else {
					this.stream = await navigator.mediaDevices.getUserMedia({
						video: false
					})
				}
			} catch (e) {
				this.$emit('testVideoAudioModalShowing', false)
			}
			this.delay = true
		}
	},
	beforeUnmount () {
		this.turnOffCamera()
	}
}
</script>

<style lang="scss" scoped>
@media (orientation: landscape) {
  .tabletVidResize {
    height:200px !important;
  }
  .micHeight {
    height: 150px !important;
  }
}
.macStyling {
  margin-top: -100px !important;
}
.altMacStyling {
  margin-top: -300px !important;
}
.audioMargin {
  margin-top:20px;
}
.audioCheckingGrid {
  gap:20px;
  display: grid;
  height:100%;
  align-items: center;
  grid-template-columns: 1fr 2fr;
}
@media (min-width: 569px) {
  .hardwareWidth {
    width:300px !important;
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .vidWidth {
    width: 293px;
  }
}
@media (max-width: 568px) {
  .hardwareWidth {
    width:85% !important;
  }
  .vidWidth {
    width:293px !important;
  }
  .vidHeight {
    height: 250px !important;
  }
  .micHeight {
    height: 150px !important;
  }
}
@media only screen and (min-width: 570px) and (max-width: 1280px) {
  .hardwareWidth {
    width:100%;
  }
  .vidWidth {
    width: 333px;
  }
}
@media (max-width: 576px) {
  .modal-dialog .modal-content {
    margin: -3px !important;
    width: calc(100vw - 10px) !important;
    height: calc(100vh - 65px) !important;
  }
}
</style>
