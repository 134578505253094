<template>
  <div class="tab">
    <div class="tab-inner mx-auto p-3 p-lg-4 d-flex flex-column align-items-center justifyContent autoScroll">
      <div
        v-if="isShineGo"
        class="text-center mb-4 font-weight-bold"
        style="font-size:0.9rem;"
      >
        {{ job.IntroScreen1Header === undefined || job.IntroScreen1Header === null ? `Introduction` : `${job.IntroScreen1Header}` }}
      </div>
      <div
        v-else
        class="text-center mb-4 font-weight-bold"
        style="font-size:0.9rem;"
      >
        About {{ job.ClientName }}
      </div>
      <div v-if="job.IntroScreen1VideoUrl !== ''" style="width:100%;max-height:500px;height:100%;background:black;">
        <video
          ref="videoQuestion"
          :src="job.IntroScreen1VideoUrl"
          :poster="job.IntroScreen1VideoThumbnailUrl"
          autoplay
          playsinline
          controls
          type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.264"
          controlsList="nodownload"
          class="mobileVideo video-fluid z-depth-1 mx-auto"
          style="outline:none;margin: 0px;border-radius: 2px;width:100%;max-height:430px;"
        />
      </div>
      <div
        v-else
        style="height:100%;width:100%;white-space: pre-wrap;font-size:0.9rem;"
        class="text-left mb-3"
      >
        {{ job.IntroScreen1 }}
      </div>
    </div>
    <div class="bottom-navigation-container">
      <mdb-btn
        size="sm"
        title="NEXT"
        alt="NEXT"
        class="btnSmall"
        color="primary"
        style="width:160px;outline:none;"
        @click="navigate()"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'IntroScreen1Details',
	components: {
	},
	props: {
	},
	data () {
		return {
		}
	},
	computed: {
		skipIntroScreen2Details () {
			return this.job.IntroScreen2VideoUrl == "" && (this.job.IntroScreen2 === undefined || this.job.IntroScreen2 === "" || this.job.IntroScreen2 === null)
		},
		...mapGetters(['job', 'isShineGo']),
		isVideo () {
			return this.job.IntroScreen1VideoUrl !== ""
		}
	},
	watch: {
	},
	created () {
	},
	updated () {
	},
	methods: {
		navigate () {
			if (!this.skipIntroScreen2Details) {
				this.$store.commit('SET_TABDESC', 'IntroScreen2Details')
			} else {
				if (this.isShineGo) {
					this.$store.commit('SET_TABDESC', 'ShineGoPreamble')
				} else {
					this.$store.commit('SET_TABDESC', 'ShinePreamble')
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
