<template>
  <mdb-modal
    v-show="avatarCropperModalShowing"
    id="avatarCropperModal"
    @close="$emit('closeAvatarCroppingModal')"
  >
    <mdb-modal-header>
      <b-row
        style="width: 80%;"
        align-h="between"
        class="mdfont-resp mx-auto pt-3"
        align-v="center"
      >
        <div class="modal-title-container m-auto m-sm-0" :class="{'modal-title-underlined' : page == 1}">
          <h6 :class="{'grey-text' : page != 1 }" @click="changePage(1)">
            <span class="d-sm-none">Camera</span> <span class="d-none d-sm-inline">take photo</span> 
            <i
              class="fa fa-camera d-sm-none ml-2"
              title="Camera icon"
              alt="Camera icon"
            />
          </h6>
        </div>
        <div class="modal-title-container m-auto m-sm-0" :class="{'modal-title-underlined' : page == 2}">
          <h6 :class="{'grey-text' : page != 2 }" @click="changePage(2)">
            <span>Upload</span> <span class="d-none d-sm-inline">photo</span> 
            <i
              class="fa fa-upload d-sm-none ml-2"
              title="Upload icon"
              alt="Upload icon"
            />
          </h6>
        </div>
      </b-row>
    </mdb-modal-header>
    <mdb-modal-body>
      <div
        v-if="input == null && image == '' && page == 1"
        style="height:100%"
        class="d-flex align-items-center justify-content-center flex-column"
      >
        <div style="background:black;height:300px;width:100%;" class="d-flex align-items-center justify-content-center">
          <video
            id="videoProfilePic"
            ref="videoProfilePic"
            autoplay
            playsinline
            type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.264"
            style="outline:none;margin: 0px;border-radius: 2px;height:300px;width:100%;object-fit: cover;"
          />
        </div>
        <canvas
          id="canvas"
          crossorigin=""
          style="display:none;"
        />
      </div>
      <div
        v-if="input == null && image == '' && page == 2"
        style="height:100%"
        class="d-flex align-items-center justify-content-center flex-column"
      >
        <i class="fa fa-user fa-10x my-4" />
        <div class="text-black text-center">
          Upload a image to begin processing your avatar
        </div>
      </div>
      <div
        v-if="input != null || image != ''"
        style="height:100%"
        class="d-flex align-items-center justify-content-center"
      >
        <Cropper
          ref="cropper"
          class="upload-example-cropper"
          :src="image"
          :stencil-component="$options.components.CircularStencil"
        />
      </div>
      <input
        ref="file"
        type="file"
        style="visibility:hidden"
        accept="image/*"
        @change="uploadImage($event)"
      >
    </mdb-modal-body>
    <mdb-modal-footer class="p-0">
      <div class="d-flex align-items-center justify-content-between" style="width:100%;">
        <mdb-btn
          v-if="page == 1 && image == ''"
          class="btn-outline mr-auto"
          size="sm"
          title="CAPTURE"
          alt="CAPTURE"
          style="width:110px;"
          @click="captureCam()"
        >
          CAPTURE
        </mdb-btn>
        <mdb-btn
          v-if="page == 2 && (input == null && image == '')"
          class="btn-outline mr-auto"
          size="sm"
          title="UPLOAD"
          alt="UPLOAD"
          style="width:100px;"
          @click="$refs.file.click()"
        >
          UPLOAD
        </mdb-btn>
        <mdb-btn
          v-if="input != null || image != ''"
          class="btn-outline mr-auto"
          size="sm"
          title="BACK"
          alt="BACK"
          style="width:100px;"
          @click="back();"
        >
          BACK
        </mdb-btn>
        <mdb-btn
          v-if="!loadingCropImage"
          :disabled="input == null && image == ''"
          size="sm"
          title="CONFIRM"
          alt="CONFIRM"
          style="width:100px;"
          @click="cropImage()"
        >
          CONFIRM
        </mdb-btn>
        <mdb-btn
          v-else
          size="sm"
          title="CONFIRMING"
          alt="CONFIRMING"
          style="width:100px;"
        >
          <i
            class="fa fa-spin fa-spinner"
            title="Confirming"
            alt="Confirming"
          />
        </mdb-btn>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import CircularStencil from './circularStencil.vue'

export default {
	name: 'AvatarCropper',
	components: {
		// eslint-disable-next-line vue/no-unused-components
		CircularStencil,
		Cropper
	},
	props: {
		imageFromProps: {
			default: '',
			type: String
		},
		avatarCropperModalShowing: Boolean,
		loadingCropImage: Boolean
	},
	data () {
		return {
			image: '',
			input: null,
			page: 1,
			stream: null
		}
	},
	watch: {
		// will close the modal after upload
		loadingCropImage () {
			if (!this.loadingCropImage && (this.input !== null || this.image !== '')) { this.$emit('closeAvatarCroppingModal') }
		}
	},
	created () {
		if (this.imageFromProps !== '') {
			this.image = this.imageFromProps
		}
		this.turnOffCamera()
		this.turnOnCamera()
	},
	methods: {
		back () {
			this.input = null
			this.image = ''
			this.turnOnCamera()
		},
		changePage (page) {
			this.page = page
			if (page == 1) { 
				this.turnOnCamera()
			}
			if (page == 2) { 
				this.turnOffCamera()
			}
		},
		captureCam () {
			this.buttonClick = true
			var video = document.getElementById('videoProfilePic')
			video.pause()
			var canvas = document.getElementById('canvas')
			var vw = video.videoWidth
			var vh = video.videoHeight
			canvas.width = vw
			canvas.height = vh
			canvas.getContext('2d').drawImage(video, 0, 0, vw, vh)
			canvas.toBlob(async blob => {
				if (blob != null) {
					var url = URL.createObjectURL(blob)
					this.image = url
				}
			})
			this.buttonClick = false
		},
		async cropImage () {
			this.$emit('setLoadingCropImage', true)
			const result = this.$refs.cropper.getResult()
			const reducedSizeCanvasURL = result.canvas.toDataURL('image/jpeg', 0.2)
			fetch(reducedSizeCanvasURL)
				.then(res => res.blob())
				.then(blob => this.$emit('handleCrop', blob))
		},
		uploadImage (event) {
			this.input = event.target
			if (this.input.files && this.input.files[0]) {
				var reader = new FileReader()
				reader.onload = (e) => {
					this.image = e.target.result
				}
				reader.readAsDataURL(this.input.files[0])
			}
		},
		async turnOnCamera () {
			this.stream = null
			try {
				if (this.mobileView) {
					this.stream = await navigator.mediaDevices.getUserMedia({
						video: {
							advanced: [
								{ width: { exact: 640 } },
								{ width: { exact: 320 } }
							],
							facingMode: { exact: "user" }
						},
						audio: false
					})
				} else {
					this.stream = await navigator.mediaDevices.getUserMedia({
						video: {
							advanced: [
								{ width: { exact: 640 } },
								{ width: { exact: 320 } }
							]
						},
						audio: false
					})
				}
				this.$refs.videoProfilePic.srcObject = this.stream
			} catch (err) {
				this.turnOffCamera()
			}
		},
		async turnOffCamera () {
			if (this.stream !== null) {
				this.stream.getTracks().forEach(track => track.stop())
				try {
					this.$refs.videoProfilePic.srcObject = null
				} catch (err) { 
					//?
				}
			}
			this.stream = null
		},
	}
}

</script>

<style lang="scss">

.upload-example-cropper {
  border: solid 1px #EEE;
  height: 350px;
  width: 100%;
}

@media (max-width: 500px) {
  #avatarCropperModal .modal-content {
    height: calc(100vh - 10px) !important;
  }
  #avatarCropperModal .modal-dialog {
    height: calc(100vh - 10px);
    margin: 5px !important;
  }
}

</style>
<style lang="scss">
</style>
