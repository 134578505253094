<template>
  <div class="tab">
    <div class="tab-inner mx-auto d-flex flex-column align-items-center autoScroll" style="height:calc(100vh - 160px);">
      <div class="d-flex align-items-center mdfont-resp justify-content-center" style="font-style:italic;width:100%; height:40px; color:#fff; background:#333; z-index:10">
        <span>ID CHECK</span>
        <div
          v-if="mobileView"
          style="position:fixed;right:0px;color:white;"
          class="d-flex align-items-center mdfont-resp font-weight-bold"
          @click="toggleCamera()"
        >
          <i
            class="fas fa-camera mr-2"
            title="Camera icon"
            alt="Camera icon"
          />
        </div>
      </div>
      <div style="height:calc(60% - 20px);position:relative;background:black;width:100%;" class="d-flex align-items-center justify-content-center">
        <video
          id="IDCapture"
          ref="IDCapture"
          playsinline
          autoplay
          muted
          type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.264"
          controlsList="nodownload"
          class="video-fluid z-depth-1 mx-auto"
          style="outline:none;margin: 0px;border-radius: 2px;height:100%;object-fit: cover; position:absolute;top:0px;"
        />       
      </div>
      <canvas
        id="canvas"
        crossorigin=""
        style="display:none;"
      />
      <div class="d-flex flex-column justify-content-center p-2 p-md-4 dark-grey-text text-center font-weight-bold" style="height:calc(40% - 20px);width:100%; font-size:clamp(1rem, 3vw, 1.2rem);">
        <span v-if="!hasCaptured">
          Please hold your identification (passport or driving license) up to the screen and press 'CAPTURE' when in focus.
          <span style="width:275px;cursor:pointer;">
            <mdb-popover
              trigger="click"
              :options="{placement: 'left'}"
              style="cursor:pointer;"
              class="grey-text"
            >
              <div slot="body" style="padding: 5px;">
                Camera not working? Try toggling the camera (if present) icon above to see if that helps. If not refresh and start over. If you still have issues, then try taking a photo anyway or skip and the recruiter will contact you in regards to this.
              </div>
              <span slot="reference"><i class="fas fa-info-circle" /></span>
            </mdb-popover>
          </span>
        </span>
        <span v-else>
          Is the image clear and in focus? Press 'NEXT' to continue or 'RE-TAKE' to try again.
          <span style="width:275px;cursor:pointer;">
            <mdb-popover
              trigger="click"
              :options="{placement: 'left'}"
              style="cursor:pointer;"
              class="grey-text"
            >
              <div slot="body" style="padding: 5px;">
                Camera not working? Try toggling the camera icon above to see if that helps. If not refresh and start over. If you still have issues, then try taking a photo anyway or skip and the recruiter will contact you in regards to this.
              </div>
              <span slot="reference"><i class="fas fa-info-circle" /></span>
            </mdb-popover>
          </span>
        </span>
      </div>
    </div>
    <div class="bottom-navigation-container flex-column">
      <span
        v-if="hasCaptured === false"
        class="dark-grey-text noId cp"
        style="font-size:0.9rem;cursor: pointer;"
        @click="$store.commit('SET_TABDESC', 'RequestIDCheck-NoIDMessage')"
      >I don't have any identification?</span>
      <div>
        <mdb-btn
          v-if="hasCaptured === false"
          size="sm"
          title="CAPTURE"
          alt="CAPTURE"
          class="btnSmall"
          color="primary"
          style="width:160px;outline:none;"
          @click="captureID()"
        >
          CAPTURE
        </mdb-btn>
        <mdb-btn
          v-if="hasCaptured"
          size="sm"
          title="RE-TAKE"
          alt="RE-TAKE"
          class="btnSmall"
          color="primary"
          style="width:160px;outline:none;"
          @click="retake()"
        >
          RE-TAKE
        </mdb-btn>
        <mdb-btn
          v-if="hasCaptured && !isUploading"
          size="sm"
          title="NEXT"
          alt="NEXT"
          class="btnSmall"
          color="primary"
          :disabled="blob === null"
          style="width:160px;outline:none;"
          @click="uploadID()"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-if="hasCaptured && isUploading"
          size="sm"
          title="NEXT"
          alt="NEXT"
          class="btnSmall"
          color="primary"
          disabled
          style="width:160px;outline:none;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import onewayService from "@/api-services/oneway.service"

export default {
	name: 'CaptureId',
	data () {
		return {
			hasCaptured: false,
			stream: null,
			isMac: false,
			iOS: false,
			mobileView: false,
			blob: null,
			isUploading: false,
			face: 'user'
		}
	},
	computed: {
		...mapGetters(['candidate', 'job']),
		skipIntroScreen1Details () {
			return this.job.IntroScreen1VideoUrl == "" && (this.job.IntroScreen1 === undefined || this.job.IntroScreen1 === "" || this.job.IntroScreen1 === null)
		}
	},
	created () {
		this.checkHardware()
	},
	mounted () {
		this.turnOnCamera()
	},
	destroyed () { 
		this.turnOffCamera()
	},
	methods: {
		async goToNextPage () {
			this.$store.commit('SET_TABDESC', 'Recording')
		},
		async uploadID () {
			this.isUploading = true
			var formData = new FormData()
			formData.append('CandidateAccountId', this.candidate.CandidateAccountId)
			formData.append('AddedByUsername', this.candidate.InvitedByUsername)
			formData.append('document', this.blob)
			try {
				await onewayService.addID(formData)
				this.goToNextPage()
			} catch (err) {
				console.log(Object.assign({}, err))
			}
			this.isUploading = false
		},
		captureID () {
			this.blob = null
			var video = document.getElementById('IDCapture')
			video.pause()
			var canvas = document.getElementById('canvas')
			var vw = video.videoWidth
			var vh = video.videoHeight
			canvas.width = vw
			canvas.height = vh
			canvas.getContext('2d').drawImage(video, 0, 0, vw, vh)
			canvas.toBlob(async blob => {
				if (blob != null) {
					this.blob = blob
				}
			})
			this.hasCaptured = true
			this.turnOffCamera()
		},
		retake () {
			this.turnOffCamera()
			this.turnOnCamera()
			this.blob = null
			this.hasCaptured = false
		},
		async toggleCamera () {
			if (this.face === 'user') {
				this.face = 'environment'
			} else {
				this.face = 'user'
			}
			try {
				this.stream = null
				this.stream = await navigator.mediaDevices.getUserMedia({
					video: {
						advanced: [
							{ width: { exact: 640 } }
						],
						facingMode: { exact: this.face }
					},
					audio: false
				})
				this.$refs.IDCapture.srcObject = this.stream
			} catch (e) {
				this.turnOffCamera()
			}
		},
		async turnOnCamera () {
			this.stream = null
			try {
				if (this.mobileView) {
					this.stream = await navigator.mediaDevices.getUserMedia({
						video: {
							advanced: [
								{ width: { exact: 640 } }
							],
							facingMode: { exact: "user" }
						},
						audio: true
					})
				} else {
					this.stream = await navigator.mediaDevices.getUserMedia({
						video: {
							advanced: [
								{ width: { exact: 640 } }
							]
						},
						audio: true
					})
				}
				this.$refs.IDCapture.srcObject = this.stream
			} catch (err) {
				this.turnOffCamera()
			}
		},
		turnOffCamera () {
			console.log('camera turned off after id check')
			this.stream.getTracks().forEach(track => track.stop())
		},
		checkHardware () {
			if (/mobile/i.test(navigator.userAgent)) {
				if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
					this.mobileView = false
				} else {
					this.mobileView = true
				}
			} else {
				this.mobileView = false
			}
			this.iOS = ['iPhone', 'iPad'].indexOf(navigator.platform) >= 0
			this.isMac = navigator.platform.indexOf('Mac') > -1

			if (navigator.userAgent.includes('Mac')) {
				this.isMac = true;
			}

			if (navigator.userAgent.includes('iPhone')) {
				this.iOS = true;
			}

			if (navigator.userAgent.includes('iPad')) {
				this.iOS = true;
			}
		
			if (this.isMac) { this.iOS = true }
		}
	}
}
</script>

<style lang="css" scoped>
.noId:hover {
  text-decoration: underline;
}
</style>
